import { isString } from 'lodash-es';

import { IValidationErrors, IValidatorInput, Validators } from '@bp/shared/features/validation/models';

const freeEmailDomains = new Set([
	'gmail.com', // Google Mail
	'yahoo.com', // Yahoo Mail
	'outlook.com', // Microsoft Outlook
	'hotmail.com', // Microsoft Hotmail
	'live.com', // Microsoft Live
	'icloud.com', // Apple iCloud Mail
	'aol.com', // AOL Mail
	'protonmail.com', // ProtonMail
	'gmx.com', // GMX Mail
	'zoho.com', // Zoho Mail
	'yandex.com', // Yandex Mail
	'mail.com', // Mail.com
	'yahoo.co.uk', // Yahoo UK
	'yahoo.co.in', // Yahoo India
	'hotmail.co.uk', // Microsoft Hotmail UK
	'hotmail.fr', // Microsoft Hotmail France
	'tutanota.com', // Tutanota Mail
]);

export function businessEmailValidator(this: void, { value }: IValidatorInput): IValidationErrors | null {
	if (Validators.isEmptyValue(value))
		return null; // Don't validate empty values to allow optional controls

	if (!isString(value))
		throw new Error('`businessEmail` validator expects string to be validated');

	const emailDomain = value.match(/@(.+)$/u)?.[1]?.toLowerCase() ?? '';

	return freeEmailDomains.has(emailDomain)
		? { businessEmail: { value } }
		: null;
}
